/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "./variables";

:root {
  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
p {
  margin: 0;
}
/****** Components ******/
// Input
.p-inputtext {
  width: 100%;
}
// Select
.p-dropdown {
  width: 100%;
}
// Multi Select
.p-multiselect {
  width: 100%;
}
// Password
.p-password {
  width: 100%;
}
.p-multiselect-filter-container {
  display: none;
}
// Checkbox
.checkbox-container {
  align-items: center;
  display: flex;
  gap: 18px;
  p-checkbox {
    .p-checkbox {
      .p-checkbox-box {
        border-color: #132E43;
        &.p-highlight {
          background-color: #132E43;
        }
      }
    }
  }
  .label {
    color: #272727;
    font-size: 18px;
    line-height: 27px;
  }
  &:not(:first-of-type) {
    margin-top: 8px;
  }
}
// Radio Button
.p-radiobutton {
  .p-radiobutton-box {
    border-color: #272727;
    &.p-highlight {
      background: transparent;
    }
    .p-radiobutton-icon {
      background-color: #272727;
    }
  }
}
.input-container {
  margin-top: 37px;
  .help-text {
    color: #272727;
    display: block;
    font-size: 15px;
    font-weight: 300;
    margin-top: 8px;
  }
  .errors-container {
    margin-top: 8px;
    .text {
      color: #E03A3A;
    }
  }
  input, textarea {
    border: 1px solid #86878C;
    & + label {
      color: #86878C;
    }
    &:focus {
      border: 1px solid #132E43;
      & + label {
        color: #132E43;
      }
    }
    &.ng-touched.ng-dirty.ng-valid.p-filled {
      border: 1px solid #272727;
      & + label {
        color: #272727;
      }
    }
    &.ng-touched.ng-dirty.ng-invalid {
      border: 1px solid #E03A3A;
      & + label {
        color: #E03A3A;
      }
    }
  }
  p-dropdown {
    .p-dropdown {
      border: 1px solid #86878C;
    }
    & + label {
      color: #86878C;
    }
    &.p-inputwrapper-focus {
      .p-dropdown {
        border: 1px solid #132E43;
      }
      & + label {
        color: #132E43;
      }
    }
    &.ng-touched.ng-dirty.ng-valid {
      .p-dropdown {
        border: 1px solid #272727;
      }
      & + label {
        color: #272727;
      }
    }
  }
  p-multiselect {
    .p-multiselect {
      border: 1px solid #86878C;
    }
    & + label {
      color: #86878C;
    }
    &.p-inputwrapper-focus {
      .p-multiselect {
        border: 1px solid #132E43;
      }
      & + label {
        color: #132E43;
      }
    }
    &.ng-touched.ng-dirty.ng-valid.p-inputwrapper-filled {
      .p-multiselect {
        border: 1px solid #272727;
      }
      & + label {
        color: #272727;
      }
    }
    &.ng-touched.ng-dirty.ng-invalid {
      .p-multiselect {
        border: 1px solid #E03A3A;
      }
      & + label {
        color: #E03A3A;
      }
    }
  }
  p-inputmask {
    input {
      border: 1px solid #86878C;
      &:focus {
        border: 1px solid #132E43;
      }
    }
    & + label {
      color: #86878C;
    }
    &:has(input:focus) {
      & + label {
        color: #132E43;
      }
    }
    &.ng-touched.ng-dirty.ng-valid {
      input {
        border: 1px solid #272727;
      }
      & + label {
        color: #272727;
      }
    }
    &.ng-touched.ng-dirty.ng-invalid {
      input {
        border: 1px solid #E03A3A;
      }
      & + label {
        color: #E03A3A;
      }
    }
  }
  p-password {
    input {
      border: 1px solid #86878C;
      &:focus {
        border: 1px solid #132E43;
      }
    }
    & + label {
      color: #86878C;
    }
    &:has(input:focus) {
      & + label {
        color: #132E43;
      }
    }
    &.ng-touched.ng-dirty.ng-valid {
      input {
        border: 1px solid #272727;
      }
      & + label {
        color: #272727;
      }
    }
    &.ng-touched.ng-dirty.ng-invalid {
      input {
        border: 1px solid #E03A3A;
      }
      & + label {
        color: #E03A3A;
      }
    }
  }
}
/****** Modals ******/
.p-dialog {
  .p-dialog-content {
    padding: 0;
  }
}
.custom-dialog {
  height: 714px;
  width: 359px;
}
.recover-password-modal {
  width: 358px;
  @media (min-width: $desktop-breakpoint) {
    width: 636px;
  }
}
.delete-confirmation-dialog {
  width: 358px;
  .content-container {
    padding: 24px 16px;
  }
}

/****** Links ******/
.custom-link {
  color: #272727;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-decoration-line: underline;
  &:hover {
    color: #86878C;
  }
}
.custom-link-two {
  color: #7598B4;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
}
.germinar-email {
  text-decoration: underline;
}
.categories-selected {
  background-color: red;
}
.filters-container {
  .p-multiselect {
    background-color: transparent;
    border-radius: 50px;
    border: 0;
    .p-multiselect-label {
      color: #132E43;
      font-size: 16px;
      font-weight: 600;
    }
    .p-multiselect-trigger {
      color: #272727;
    }
  }
}
// TODO: Normalize breakpoints
@media (width >= 1280px) {
  /****** Modals ******/
  .custom-dialog {
    height: 717px;
    width: 636px;
    p-button {
      width: 358px;
    }
  }
  .filters-container {
    .p-multiselect {
      .p-multiselect-label {
        font-size: 20px;
      }
    }
  }
}
